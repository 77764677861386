@media screen and (max-width: 767px) {
}@media (min-width: 768px) {
}@media (min-width: 768px) {
}@media (max-width: 767px) {
}@media (min-width: 768px) {
}@media (min-width: 768px) {
}@media (max-width: 767px) {
}@media (max-width: 767px) {
}.main_1sSBe {
  position: fixed;
  top: 51px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
}
