.loader_xtY_M {
  width: 100%;
  height: 100%;
  min-height: 10rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.spinner_17os1 {
  margin: auto;
  font-size: 5em;
}
.spinner_17os1 {
  -webkit-animation: loader-spin 1s infinite linear;
          animation: loader-spin 1s infinite linear;
}
@-webkit-keyframes loader-spin {
  from {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
  }
  to {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg);
  }
}
@keyframes loader-spin {
  from {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
  }
  to {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg);
  }
}
