.keybox_3IJkZ {
  font-family: 'Roboto Mono', monospace;
  margin-right: 3em;
}
.keybox_3IJkZ tr {
  height: 1em;
}
.keybox_3IJkZ td {
  border: solid black 1px;
  width: 1.5em;
  text-align: center;
  vertical-align: middle;
}
.keyboxContainer_1mwCC {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
